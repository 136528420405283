const InitialLoader = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-4">
      <img
        loading="lazy"
        className="w-10 h-10 animate-spin"
        src="/logo.svg"
        alt="logo"
        decoding="async"
        width={40}
        height={40}
      />
      <img
        loading="lazy"
        className="h-5"
        src="/logo-plain.svg"
        alt="logo"
        decoding="async"
        height={20}
      />
    </div>
  );
};

export default InitialLoader;
