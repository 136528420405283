window.addEventListener("vite:preloadError", (event) => {
  event.preventDefault();

  logtail.error("Failed to download assets", {
    error: { message: "Failed to download assets", code: "404", event: "vite:preloadError" },
  });

  if (confirm("A new version is available. Refresh now?")) {
    window.location.reload();
  }
});

import { ClerkLoaded, ClerkLoading, ClerkProvider } from "@clerk/clerk-react";
import { dark } from "@clerk/themes";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { NuqsAdapter } from "nuqs/adapters/react";
import React, { FC, ReactNode, Suspense, lazy, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { ThemeConfig } from "./configs/theme.tsx";
import InitialLoader from "./generics/initial-loader/index.tsx";
import { ErrorBoundary } from "./utils/error-boundary";
import "@carbon/charts-react/styles.css";
import "@xyflow/react/dist/style.css";
import "../app/globals.css";
import { datadogRum } from "@datadog/browser-rum";
import { injectSpeedInsights } from "@vercel/speed-insights";
import { useTheme } from "next-themes";
import { PostHogProvider } from "posthog-js/react";
import { env } from "./env.ts";
import { logtail } from "./configs/logtail.ts";
injectSpeedInsights();

const PUBLISHABLE_KEY = env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) throw new Error("Add your Clerk publishable key to the .env.local file");

const App = lazy(() => import("./App.tsx"));

datadogRum.init({
  applicationId: env.VITE_DATADOG_APPLICATION_ID,
  clientToken: env.VITE_DATADOG_CLIENT_TOKEN,
  site: env.VITE_DATADOG_SITE,
  service: env.VITE_DATADOG_SERVICE,
  env: env.VITE_ENVIRONMENT,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();

Sentry.init({
  dsn: env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  release: "react-sentry-github@1.0.0",
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/ebrai\.com\/api/],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  environment: env.VITE_ENVIRONMENT,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 30000,
    },
    mutations: {
      retry: false,
    },
  },
});

const ClerkWithRouter: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { resolvedTheme } = useTheme();

  useEffect(() => {
    const handleRouterPush = (to: string) => navigate(to);
    const handleRouterReplace = (to: string) => navigate(to, { replace: true });

    window.ClerkRouterPush = handleRouterPush;
    window.ClerkRouterReplace = handleRouterReplace;
  }, []);

  return (
    <ClerkProvider
      routerPush={(to) => window.ClerkRouterPush?.(to)}
      routerReplace={(to) => window.ClerkRouterReplace?.(to)}
      publishableKey={PUBLISHABLE_KEY}
      signInUrl={env.VITE_CLERK_SIGN_IN_URL}
      signUpUrl={env.VITE_CLERK_SIGN_UP_URL}
      appearance={{ baseTheme: resolvedTheme === "dark" ? dark : undefined }}
      afterSignOutUrl={env.VITE_CLERK_AFTER_SIGN_OUT_URL}
      signUpForceRedirectUrl={env.VITE_CLERK_SIGN_UP_FORCE_REDIRECT_URL}
      signInForceRedirectUrl={env.VITE_CLERK_SIGN_IN_FORCE_REDIRECT_URL}
    >
      <ClerkLoading>
        <InitialLoader />
      </ClerkLoading>
      <ClerkLoaded>{children}</ClerkLoaded>
    </ClerkProvider>
  );
};

export const GlobalWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Suspense fallback={<InitialLoader />}>
      <HelmetProvider>
        <BrowserRouter>
          <PostHogProvider
            apiKey={env.VITE_PUBLIC_POSTHOG_KEY}
            options={{
              api_host: env.VITE_PUBLIC_POSTHOG_HOST,
            }}
          >
            <ThemeConfig>
              <ClerkWithRouter>
                <QueryClientProvider client={queryClient}>
                  <ReactQueryDevtools />
                  <NuqsAdapter>{children}</NuqsAdapter>
                </QueryClientProvider>
              </ClerkWithRouter>
            </ThemeConfig>
          </PostHogProvider>
        </BrowserRouter>
      </HelmetProvider>
    </Suspense>
  );
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <GlobalWrapper>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </GlobalWrapper>,
);
