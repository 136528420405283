import { createEnv } from "@t3-oss/env-core";
import { z } from "zod";

export const env = createEnv({
  server: {},
  clientPrefix: "VITE_",
  client: {
    VITE_SUPABASE_URL: z.string().min(1),
    VITE_PUBLIC_SUPABASE_ANON_KEY: z.string().min(1),
    // Clerk
    VITE_CLERK_SIGN_IN_URL: z.string().min(1),
    VITE_CLERK_SIGN_UP_URL: z.string().min(1),
    VITE_CLERK_PUBLISHABLE_KEY: z.string().min(1),
    VITE_CLERK_SUPABASE_TEMPLATE: z.string().min(1),
    VITE_CLERK_AFTER_SIGN_OUT_URL: z.string().min(1),
    VITE_CLERK_SIGN_IN_FORCE_REDIRECT_URL: z.string().min(1),
    VITE_CLERK_SIGN_UP_FORCE_REDIRECT_URL: z.string().min(1),
    // PostHog
    VITE_PUBLIC_POSTHOG_KEY: z.string().min(1),
    VITE_PUBLIC_POSTHOG_HOST: z.string().min(1),
    // Courier
    VITE_COURIER_CLIENT_KEY: z.string().min(1),
    // Datadog
    VITE_DATADOG_CLIENT_TOKEN: z.string().min(1),
    VITE_DATADOG_SITE: z.string().min(1),
    VITE_DATADOG_SERVICE: z.string().min(1),
    VITE_DATADOG_APPLICATION_ID: z.string().min(1),
    // Arcjet
    VITE_ARCJET_API_KEY: z.string().min(1),
    // Sentry
    VITE_SENTRY_DSN: z.string().min(1),
    // Environment
    VITE_ENVIRONMENT: z.enum(["dev", "stg", "pre", "main"]),
    // BetterStack
    VITE_BETTER_STACK_INGESTING_HOST: z.string().min(1),
    VITE_BETTER_STACK_SOURCE_TOKEN: z.string().min(1),
  },
  runtimeEnv: import.meta.env,
  emptyStringAsUndefined: true,
});
