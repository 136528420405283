import { logtail } from "@/configs/logtail";
import ErrorBoundaryState from "@/generics/errors/error-boundary";
import React from "react";

export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    console.info("%c Caught an error!", "padding: 5px; background: #e5e0d4; color: #ff1f1f;");
    console.error(error);
    logtail.error("React Component Error", { error });
  }

  render() {
    return this.state.hasError ? (
      <div {...this.props} className="grid grid-cols-1 content-center w-full  h-screen">
        <ErrorBoundaryState />
      </div>
    ) : (
      this.props.children
    );
  }
}
