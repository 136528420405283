import { Warning_02 as Warning02 } from "@carbon/pictograms-react";
import { Button, Link } from "@carbon/react";
import { Chat, Dashboard } from "@carbon/react/icons";
import { show } from "@intercom/messenger-js-sdk";

const ErrorBoundaryState = () => {
  return (
    <div className="w-full h-full flex flex-col items-center">
      <Warning02 fontSize={30} />
      <h5 className="mt-8 font-medium text-2xl">Oops! Something went wrong.</h5>
      <span className="text-md mt-4">Error code: 500</span>
      <p className="max-w-[500px] text-center mt-4">
        There was a problem connecting to Ebrai. Please{" "}
        <Link href="/dashboard">refresh the page</Link> or check your connection.
      </p>

      <div className="flex gap-4 mt-4">
        <Button renderIcon={Chat} kind="secondary" onClick={() => show()}>
          Need Help?
        </Button>
        <Button renderIcon={Dashboard} href="/dashboard">
          Return to Dashboard
        </Button>
      </div>
    </div>
  );
};

export default ErrorBoundaryState;
