import { Theme } from "@carbon/react";
import { ThemeProvider, useTheme } from "next-themes";
import { type FC, useEffect } from "react";

type ThemeProviderProps = Readonly<{ children: React.ReactNode }>;

const CarbonTheme: FC<ThemeProviderProps> = ({ children }) => {
  const { resolvedTheme } = useTheme();

  useEffect(() => {
    if (resolvedTheme === "dark") {
      document.documentElement.classList.add("dark");
      document.body.style.backgroundColor = "#161616";
    } else {
      document.documentElement.classList.remove("dark");
      document.body.style.backgroundColor = "#ffffff";
    }
  }, [resolvedTheme]);

  return <Theme theme={resolvedTheme === "dark" ? "g100" : "white"}>{children}</Theme>;
};

export const ThemeConfig: FC<ThemeProviderProps> = ({ children }) => {
  return (
    <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
      <CarbonTheme>{children}</CarbonTheme>
    </ThemeProvider>
  );
};
